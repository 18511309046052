import React from 'react';
import { Layout, Menu, Breadcrumb } from 'antd';
import { Card } from 'antd';
import { Row, Col } from 'antd';
import {Link} from "react-router-dom";


const { Meta } = Card;
const { Header, Content, Footer } = Layout;


class SiderDemo extends React.Component {
  state = {
    collapsed: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  render() {
    return (
      <Layout className="layout">
 
    <Content style={{ padding: '0 50px' }}>
      <div style={{ background: '#fff', padding: 24, minHeight: 280 }}>
        
    
    <div>
      <Row type="flex" justify="space-around">
        <Col span={6}>
            
            <Card
                hoverable
                style={{ width: 280  }}
                cover={<img alt="example" src="http://www.responsabilidadsocial.net/wp-content/uploads/2015/11/images_articulos_2015_capital_social_responsabilidad_social_empresas_socialmente_responsables.jpg" />}
                >
                <Meta
                title="Comienza tu campaña"
                description="Esta es una plataforma que te permite recaudar fondos de marcas y compañias socialmente responsables"
                />
            </Card>

        </Col>
       
        
  <Col span={6}>
  <Card
    hoverable
    style={{ width: 280 }}
    cover={<img alt="example" src="https://smilemkt.com.ar/wp-content/uploads/2018/10/Blog-43.jpg" />}
  >
    <Meta
      title="Tiempor de ser social!"
      description="Busca proyectos de ayuda social para porporcionar fondos"
    />
  </Card>
</Col>
</Row>
</div>

      </div>
    </Content>
    <Footer style={{ textAlign: 'center' }}>
      Ironhack 2019 - Project 3
    </Footer>
  </Layout>
    )
}
}

export default SiderDemo