import React from 'react';
import { Layout, Menu, Breadcrumb } from 'antd';
import {Link} from "react-router-dom";
const { Header, Content, Footer } = Layout;


class Navbar extends React.Component {
  state = {
    collapsed: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  render() {
    return (
      <Layout className="layout">
    <Header>
      <div className="logo" />
      <Menu
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={['2']}
        style={{ lineHeight: '64px' }}
      >
        <Menu.Item key="1"><Link to="/">Home</Link></Menu.Item>
        <Menu.Item key="2"><Link to="/">Quienes somos?</Link></Menu.Item>
        <Menu.Item key="3"><Link to="/login">Login</Link></Menu.Item>   
        <Menu.Item key="4"><Link to="/userRegister">Registrate!</Link></Menu.Item>   
      </Menu>

    </Header>
  
  </Layout>
    )
}
}

export default Navbar


