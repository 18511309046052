import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';

import SiderDemo from './components/SiderDemo';
import WrappedDemo from './components/UserRegister'
import {Switch, Route} from 'react-router-dom';
import Navbar from './components/Navbar';
import Login from './components/Login';


class App extends Component {
  render() {
    return (
      <div className="App">
        <Navbar/>
        <Switch>
        <Route exact path="/" component={SiderDemo}></Route>  
        <Route exact path="/login" component={Login}></Route>
        <Route exact path="/userRegister" component={WrappedDemo}></Route>
    
        </Switch>
      </div>
    );
  }
}

export default App;



